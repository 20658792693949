
import { supabase } from "@/integrations/supabase/client";
import { 
  GalleryAlbum, GalleryAlbumWithItems, GalleryImage, GalleryVideo,
  DbGalleryAlbum, DbGalleryImage, DbGalleryVideo
} from "@/types/gallery";
import { 
  transformAlbum, transformImage, transformVideo, transformAlbumWithImages 
} from "@/utils/dataTransformUtils";

// Fetch all albums
export async function fetchGalleryAlbums(): Promise<GalleryAlbum[]> {
  const { data, error } = await supabase
    .from('gallery_albums')
    .select('*')
    .order('created_at', { ascending: false });
  
  if (error) {
    console.error('Error fetching gallery albums:', error);
    throw error;
  }
  
  return (data || []).map((item: DbGalleryAlbum) => transformAlbum(item));
}

// Fetch album with images
export async function fetchAlbumWithImages(albumId: string): Promise<GalleryAlbumWithItems | null> {
  // First fetch the album
  const { data: albumData, error: albumError } = await supabase
    .from('gallery_albums')
    .select('*')
    .eq('id', albumId)
    .single();
  
  if (albumError) {
    console.error('Error fetching album:', albumError);
    throw albumError;
  }
  
  if (!albumData) return null;
  
  // Then fetch the images for this album
  const { data: imagesData, error: imagesError } = await supabase
    .from('gallery_images')
    .select('*')
    .eq('album_id', albumId)
    .order('sort_order', { ascending: true });
  
  if (imagesError) {
    console.error('Error fetching album images:', imagesError);
    throw imagesError;
  }
  
  return transformAlbumWithImages(
    albumData as DbGalleryAlbum,
    (imagesData || []) as DbGalleryImage[]
  );
}

// Fetch all videos
export async function fetchGalleryVideos(): Promise<GalleryVideo[]> {
  const { data, error } = await supabase
    .from('gallery_videos')
    .select('*')
    .order('created_at', { ascending: false });
  
  if (error) {
    console.error('Error fetching gallery videos:', error);
    throw error;
  }
  
  return (data || []).map((item: DbGalleryVideo) => transformVideo(item));
}

// Get public URL for an image or video from storage
export function getMediaUrl(path: string, mediaType: 'image' | 'video' = 'image'): string {
  if (!path) return '';
  
  // If it's already a full URL, return it
  if (path.startsWith('http')) {
    return path;
  }
  
  // Choose the appropriate bucket based on media type and path pattern
  let bucket = 'image-gallery';
  
  // For YouTube videos, return the URL directly
  if (mediaType === 'video' && (path.includes('youtube.com') || path.includes('youtu.be'))) {
    return path;
  }
  
  // For special paths that indicate specific bucket locations
  if (path.startsWith('Sports 2025/')) {
    bucket = 'school-events';
  } else if (path.startsWith('lovable-uploads/')) {
    bucket = 'website-images';
  }
  
  // Get the URL from Supabase storage
  const { data } = supabase.storage
    .from(bucket)
    .getPublicUrl(path);
    
  return data.publicUrl;
}

// Alias for backward compatibility
export function getImageUrl(path: string): string {
  return getMediaUrl(path, 'image');
}

// Get video URL specifically
export function getVideoUrl(path: string): string {
  return getMediaUrl(path, 'video');
}
