
import { useState, useEffect } from 'react';
import { fetchGalleryAlbums } from '@/services/galleryService';
import { GalleryAlbum } from '@/types/gallery';
import { useToast } from '@/hooks/use-toast';

export function useGalleryAlbums() {
  const [albums, setAlbums] = useState<GalleryAlbum[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const { toast } = useToast();

  useEffect(() => {
    const loadAlbums = async () => {
      try {
        setIsLoading(true);
        setError(null);
        const data = await fetchGalleryAlbums();
        setAlbums(data);
      } catch (err) {
        console.error('Failed to load gallery albums:', err);
        setError(err instanceof Error ? err : new Error('Failed to load albums'));
        toast({
          title: "Failed to load albums",
          description: "There was an error loading the gallery albums.",
          variant: "destructive"
        });
      } finally {
        setIsLoading(false);
      }
    };
    
    loadAlbums();
  }, [toast]);

  return { albums, isLoading, error };
}
