
import React, { useState } from 'react';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import { useGalleryAlbums } from '@/hooks/useGalleryAlbums';
import { useGalleryAlbumDetails } from '@/hooks/useGalleryAlbumDetails';
import { AlbumGrid } from './AlbumGrid';
import { AlbumViewer } from './AlbumViewer';

export function ImageGallery() {
  const { albums, isLoading } = useGalleryAlbums();
  const [selectedAlbumId, setSelectedAlbumId] = useState<string | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  
  const { album, isLoading: isLoadingAlbum } = useGalleryAlbumDetails(
    dialogOpen ? selectedAlbumId : null
  );
  
  const handleAlbumClick = (albumId: string) => {
    setSelectedAlbumId(albumId);
    setDialogOpen(true);
  };
  
  return (
    <div>
      <AlbumGrid 
        albums={albums} 
        onAlbumClick={handleAlbumClick} 
        isLoading={isLoading} 
      />
      
      <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
        <DialogContent className="max-w-4xl">
          <AlbumViewer 
            album={album} 
            onClose={() => setDialogOpen(false)} 
            isLoading={isLoadingAlbum}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
