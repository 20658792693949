
import { useState, useEffect } from 'react';
import { fetchGalleryVideos } from '@/services/galleryService';
import { GalleryVideo } from '@/types/gallery';
import { useToast } from '@/hooks/use-toast';

export function useGalleryVideos() {
  const [videos, setVideos] = useState<GalleryVideo[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const { toast } = useToast();
  
  useEffect(() => {
    const loadVideos = async () => {
      try {
        setIsLoading(true);
        setError(null);
        const data = await fetchGalleryVideos();
        console.log('Loaded videos:', data);
        setVideos(data);
      } catch (err) {
        console.error('Failed to load gallery videos:', err);
        setError(err instanceof Error ? err : new Error('Failed to load videos'));
        toast({
          title: "Failed to load videos",
          description: "There was an error loading the gallery videos.",
          variant: "destructive"
        });
      } finally {
        setIsLoading(false);
      }
    };
    
    loadVideos();
  }, [toast]);

  return { videos, isLoading, error };
}
