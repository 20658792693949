
/**
 * Video utilities for handling video URLs and formats
 */

/**
 * Extract YouTube video ID from various URL formats
 * @param url YouTube URL in any format
 * @returns YouTube video ID if found, empty string otherwise
 */
export function extractYouTubeVideoId(url: string): string {
  if (!url) return '';
  
  // Handle various YouTube URL formats
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);
  
  return (match && match[2].length === 11) ? match[2] : '';
}

/**
 * Convert a YouTube URL to an embed URL
 * @param url YouTube URL in any format
 * @returns YouTube embed URL
 */
export function getYouTubeEmbedUrl(url: string): string {
  const videoId = extractYouTubeVideoId(url);
  if (!videoId) return url; // If not a valid YouTube URL, return as is
  
  return `https://www.youtube.com/embed/${videoId}`;
}

/**
 * Get YouTube thumbnail URL for a video
 * @param url YouTube URL in any format
 * @returns URL to the video thumbnail
 */
export function getYouTubeThumbnailUrl(url: string): string {
  const videoId = extractYouTubeVideoId(url);
  if (!videoId) return '';
  
  // Return the high quality thumbnail
  return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
}
