
import React from 'react';
import { useAnnouncement } from '@/context/AnnouncementContext';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { X } from 'lucide-react';

export const AnnouncementBanner = () => {
  const { announcement } = useAnnouncement();

  if (!announcement || !announcement.is_active) {
    return null;
  }

  return (
    <Alert className="rounded-none border-b border-t-0 border-x-0 bg-primary text-primary-foreground shadow-none">
      <AlertDescription className="flex items-center justify-center py-1">
        <span className="text-sm font-medium">{announcement.message}</span>
      </AlertDescription>
    </Alert>
  );
};
