
import React, { useState } from 'react';
import { Button } from '@/components/ui/button';
import { ChevronLeft, ChevronRight, ImageIcon, Loader2 } from 'lucide-react';
import { GalleryAlbumWithItems } from '@/types/gallery';
import { getImageUrl } from '@/services/galleryService';

type AlbumViewerProps = {
  album: GalleryAlbumWithItems | null;
  onClose: () => void;
  isLoading: boolean;
};

export function AlbumViewer({ album, onClose, isLoading }: AlbumViewerProps) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [imageError, setImageError] = useState<Record<string, boolean>>({});
  
  if (isLoading) {
    return (
      <div className="flex justify-center items-center py-16">
        <Loader2 className="w-8 h-8 animate-spin text-primary" />
      </div>
    );
  }
  
  if (!album) return null;
  
  const nextImage = () => {
    setCurrentImageIndex((prev) => 
      prev === album.images.length - 1 ? 0 : prev + 1
    );
  };
  
  const prevImage = () => {
    setCurrentImageIndex((prev) => 
      prev === 0 ? album.images.length - 1 : prev - 1
    );
  };
  
  if (album.images.length === 0) {
    return (
      <div>
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">{album.title}</h2>
          <Button variant="ghost" size="sm" onClick={onClose}>Close</Button>
        </div>
        <div className="text-center py-12">
          <ImageIcon className="mx-auto h-12 w-12 text-muted-foreground mb-4" />
          <h3 className="text-lg font-medium mb-2">No Images in Album</h3>
          <p className="text-muted-foreground">This album does not contain any images yet.</p>
        </div>
      </div>
    );
  }
  
  const handleImageError = (index: number) => {
    setImageError(prev => ({
      ...prev,
      [index]: true
    }));
  };
  
  return (
    <div className="relative">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">{album.title}</h2>
        <Button variant="ghost" size="sm" onClick={onClose}>Close</Button>
      </div>
      
      <div className="relative aspect-video bg-black rounded-lg overflow-hidden">
        {imageError[currentImageIndex] ? (
          <div className="flex items-center justify-center h-full">
            <ImageIcon className="w-16 h-16 text-muted-foreground" />
            <p className="text-muted-foreground ml-2">Image could not be loaded</p>
          </div>
        ) : (
          <img 
            src={getImageUrl(album.images[currentImageIndex].imageUrl)} 
            alt={album.images[currentImageIndex].title || `Image ${currentImageIndex + 1}`} 
            className="w-full h-full object-contain"
            onError={() => handleImageError(currentImageIndex)}
          />
        )}
        
        <Button 
          variant="secondary" 
          size="icon" 
          className="absolute left-2 top-1/2 -translate-y-1/2 rounded-full"
          onClick={prevImage}
        >
          <ChevronLeft className="h-4 w-4" />
        </Button>
        
        <Button 
          variant="secondary" 
          size="icon" 
          className="absolute right-2 top-1/2 -translate-y-1/2 rounded-full"
          onClick={nextImage}
        >
          <ChevronRight className="h-4 w-4" />
        </Button>
      </div>
      
      <div className="mt-2 text-center text-sm text-muted-foreground">
        Image {currentImageIndex + 1} of {album.images.length}
      </div>
      
      <div className="grid grid-cols-6 gap-2 mt-4">
        {album.images.map((image, index) => (
          <div 
            key={image.id}
            className={`aspect-square cursor-pointer rounded-md overflow-hidden ${
              index === currentImageIndex ? 'ring-2 ring-primary' : ''
            }`}
            onClick={() => setCurrentImageIndex(index)}
          >
            {imageError[index] ? (
              <div className="flex items-center justify-center h-full bg-muted">
                <ImageIcon className="w-6 h-6 text-muted-foreground" />
              </div>
            ) : (
              <img 
                src={getImageUrl(image.thumbnailUrl || image.imageUrl)} 
                alt={`Thumbnail ${index + 1}`} 
                className="w-full h-full object-cover"
                onError={() => handleImageError(index)}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
