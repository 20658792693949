
/**
 * Utility functions for transforming data between database and UI formats
 */

import { 
  DbGalleryAlbum, GalleryAlbum,
  DbGalleryImage, GalleryImage,
  DbGalleryVideo, GalleryVideo,
  GalleryAlbumWithItems
} from "@/types/gallery";

/**
 * Transform a snake_case database album to camelCase UI model
 */
export function transformAlbum(dbAlbum: DbGalleryAlbum): GalleryAlbum {
  return {
    id: dbAlbum.id,
    title: dbAlbum.title,
    description: dbAlbum.description,
    coverImageUrl: dbAlbum.cover_image_url,
    createdAt: dbAlbum.created_at,
    updatedAt: dbAlbum.updated_at,
    itemCount: dbAlbum.item_count
  };
}

/**
 * Transform a snake_case database image to camelCase UI model
 */
export function transformImage(dbImage: DbGalleryImage): GalleryImage {
  return {
    id: dbImage.id,
    albumId: dbImage.album_id,
    title: dbImage.title,
    description: dbImage.description,
    imageUrl: dbImage.image_url,
    thumbnailUrl: dbImage.thumbnail_url,
    createdAt: dbImage.created_at,
    updatedAt: dbImage.updated_at,
    sortOrder: dbImage.sort_order
  };
}

/**
 * Transform a snake_case database video to camelCase UI model
 */
export function transformVideo(dbVideo: DbGalleryVideo): GalleryVideo {
  return {
    id: dbVideo.id,
    title: dbVideo.title,
    description: dbVideo.description,
    videoUrl: dbVideo.video_url,
    thumbnailUrl: dbVideo.thumbnail_url,
    duration: dbVideo.duration,
    createdAt: dbVideo.created_at,
    updatedAt: dbVideo.updated_at
  };
}

/**
 * Transform an album with its images into a GalleryAlbumWithItems
 */
export function transformAlbumWithImages(
  dbAlbum: DbGalleryAlbum, 
  dbImages: DbGalleryImage[]
): GalleryAlbumWithItems {
  return {
    ...transformAlbum(dbAlbum),
    images: dbImages.map(transformImage)
  };
}
