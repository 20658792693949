
import { useState, useEffect } from 'react';
import { fetchAlbumWithImages } from '@/services/galleryService';
import { GalleryAlbumWithItems } from '@/types/gallery';
import { useToast } from '@/hooks/use-toast';

export function useGalleryAlbumDetails(albumId: string | null) {
  const [album, setAlbum] = useState<GalleryAlbumWithItems | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const { toast } = useToast();

  useEffect(() => {
    if (!albumId) return;

    const loadAlbumDetails = async () => {
      try {
        setIsLoading(true);
        setError(null);
        const data = await fetchAlbumWithImages(albumId);
        setAlbum(data);
      } catch (err) {
        console.error('Failed to load album details:', err);
        setError(err instanceof Error ? err : new Error('Failed to load album details'));
        toast({
          title: "Error loading album",
          description: "The selected album could not be loaded",
          variant: "destructive"
        });
      } finally {
        setIsLoading(false);
      }
    };
    
    loadAlbumDetails();
  }, [albumId, toast]);

  return { album, isLoading, error };
}
