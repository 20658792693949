
import React, { useState } from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { Calendar, VideoIcon } from 'lucide-react';
import { GalleryVideo } from '@/types/gallery';
import { getMediaUrl } from '@/services/galleryService';

interface VideoCardProps {
  video: GalleryVideo;
  onClick: (videoId: string) => void;
  onError?: () => void;
}

export function VideoCard({ video, onClick, onError }: VideoCardProps) {
  const [thumbnailError, setThumbnailError] = useState(false);
  
  const handleThumbnailError = () => {
    setThumbnailError(true);
    if (onError) onError();
  };
  
  return (
    <Card className="overflow-hidden hover:shadow-lg transition-shadow">
      <div 
        className="relative h-48 cursor-pointer" 
        onClick={() => onClick(video.id)}
      >
        {video.thumbnailUrl && !thumbnailError ? (
          <img 
            src={getMediaUrl(video.thumbnailUrl, 'image')} 
            alt={video.title} 
            className="w-full h-full object-cover"
            onError={handleThumbnailError}
          />
        ) : (
          <div className="w-full h-full flex items-center justify-center bg-muted">
            <VideoIcon className="w-12 h-12 text-muted-foreground" />
          </div>
        )}
        <div className="absolute inset-0 bg-black/40 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity">
          <VideoIcon className="w-12 h-12 text-white" />
        </div>
      </div>
      <CardContent className="p-4">
        <h3 className="text-lg font-medium mb-1">{video.title}</h3>
        {video.description && (
          <p className="text-sm text-muted-foreground mb-2">{video.description}</p>
        )}
        <div className="flex justify-between items-center mt-2">
          <div className="flex items-center gap-1">
            <Calendar className="h-3 w-3 text-muted-foreground" />
            <span className="text-xs text-muted-foreground">
              {new Date(video.createdAt).toLocaleDateString()}
            </span>
          </div>
          {video.duration && (
            <span className="text-xs font-medium">{video.duration}</span>
          )}
        </div>
      </CardContent>
    </Card>
  );
}
