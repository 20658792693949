
import React, { useState } from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { VideoIcon, ExternalLink, Calendar, Loader2 } from 'lucide-react';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import { AspectRatio } from '@/components/ui/aspect-ratio';
import { useGalleryVideos } from '@/hooks/useGalleryVideos';
import { GalleryVideo } from '@/types/gallery';
import { getYouTubeEmbedUrl } from '@/utils/videoUtils';
import { VideoCard } from './VideoCard';
import { getMediaUrl } from '@/services/galleryService';

export function VideoGallery() {
  const { videos, isLoading } = useGalleryVideos();
  const [selectedVideo, setSelectedVideo] = useState<GalleryVideo | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [videoError, setVideoError] = useState<Record<string, boolean>>({});
  
  const handleVideoClick = (videoId: string) => {
    const video = videos.find(v => v.id === videoId);
    if (video) {
      setSelectedVideo(video);
      setDialogOpen(true);
    }
  };

  const handleOpenExternal = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  
  const handleVideoError = (videoId: string) => {
    setVideoError(prev => ({
      ...prev,
      [videoId]: true
    }));
  };
  
  if (isLoading) {
    return (
      <div className="flex justify-center items-center py-16">
        <Loader2 className="w-8 h-8 animate-spin text-primary" />
      </div>
    );
  }
  
  if (videos.length === 0) {
    return (
      <Card className="bg-muted/50">
        <CardContent className="p-8">
          <div className="text-center py-12">
            <VideoIcon className="mx-auto h-12 w-12 text-muted-foreground mb-4" />
            <h3 className="text-lg font-medium mb-2">No Videos Available</h3>
            <p className="text-muted-foreground">There are currently no videos to display.</p>
          </div>
        </CardContent>
      </Card>
    );
  }
  
  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {videos.map((video) => (
          <VideoCard 
            key={video.id} 
            video={video} 
            onClick={handleVideoClick}
            onError={() => handleVideoError(video.id)}
          />
        ))}
      </div>
      
      <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
        <DialogContent className="max-w-4xl p-6">
          {selectedVideo && (
            <div className="space-y-4">
              <div className="flex justify-between items-center">
                <h2 className="text-xl font-bold">{selectedVideo.title}</h2>
                <Button 
                  variant="outline" 
                  size="sm" 
                  className="gap-1"
                  onClick={() => handleOpenExternal(selectedVideo.videoUrl)}
                >
                  <ExternalLink className="h-4 w-4" />
                  Open in YouTube
                </Button>
              </div>
              
              <AspectRatio ratio={16 / 9}>
                {videoError[selectedVideo.id] ? (
                  <div className="w-full h-full flex items-center justify-center bg-muted rounded-md">
                    <div className="text-center">
                      <VideoIcon className="mx-auto h-16 w-16 text-muted-foreground mb-2" />
                      <p className="text-muted-foreground">Video could not be loaded</p>
                    </div>
                  </div>
                ) : (
                  selectedVideo.videoUrl.includes('youtube.com') || selectedVideo.videoUrl.includes('youtu.be') ? (
                    <iframe
                      src={getYouTubeEmbedUrl(selectedVideo.videoUrl)}
                      title={selectedVideo.title}
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      className="w-full h-full rounded-md"
                      onError={() => handleVideoError(selectedVideo.id)}
                    ></iframe>
                  ) : (
                    <video
                      src={getMediaUrl(selectedVideo.videoUrl, 'video')}
                      title={selectedVideo.title}
                      controls
                      className="w-full h-full rounded-md object-contain bg-black"
                      onError={() => handleVideoError(selectedVideo.id)}
                    />
                  )
                )}
              </AspectRatio>
              
              {selectedVideo.description && (
                <p className="text-sm text-muted-foreground">{selectedVideo.description}</p>
              )}
              <div className="flex items-center gap-4">
                <div className="flex items-center gap-1">
                  <Calendar className="h-4 w-4 text-muted-foreground" />
                  <span className="text-sm">
                    {new Date(selectedVideo.createdAt).toLocaleDateString()}
                  </span>
                </div>
                {selectedVideo.duration && (
                  <span className="text-sm">Duration: {selectedVideo.duration}</span>
                )}
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
