
import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { supabase } from '@/integrations/supabase/client';
import { toast } from 'sonner';

type Announcement = {
  id: string;
  message: string;
  is_active: boolean;
  created_at: string;
  updated_at: string;
};

interface AnnouncementContextType {
  announcement: Announcement | null;
  loading: boolean;
  error: Error | null;
  updateAnnouncement: (id: string, message: string, is_active: boolean) => Promise<void>;
  fetchAnnouncements: () => Promise<void>;
}

const AnnouncementContext = createContext<AnnouncementContextType | undefined>(undefined);

export const AnnouncementProvider = ({ children }: { children: ReactNode }) => {
  const [announcement, setAnnouncement] = useState<Announcement | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchAnnouncements = async () => {
    try {
      setLoading(true);
      setError(null);
      
      const { data, error } = await supabase
        .from('site_announcements')
        .select('*')
        .order('updated_at', { ascending: false });
      
      if (error) {
        throw error;
      }

      // Find the active announcement, or use the most recent one if none are active
      const activeAnnouncement = data.find(ann => ann.is_active) || data[0] || null;
      setAnnouncement(activeAnnouncement);
    } catch (err) {
      console.error('Error fetching announcements:', err);
      setError(err instanceof Error ? err : new Error('Failed to fetch announcements'));
    } finally {
      setLoading(false);
    }
  };

  const updateAnnouncement = async (id: string, message: string, is_active: boolean) => {
    try {
      setLoading(true);
      
      const { data, error } = await supabase
        .from('site_announcements')
        .update({ message, is_active, updated_at: new Date().toISOString() })
        .eq('id', id)
        .select()
        .single();
      
      if (error) {
        throw error;
      }
      
      // Refresh announcements after update
      await fetchAnnouncements();
      toast.success('Announcement updated successfully');
    } catch (err) {
      console.error('Error updating announcement:', err);
      toast.error('Failed to update announcement');
      setError(err instanceof Error ? err : new Error('Failed to update announcement'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAnnouncements();
  }, []);

  return (
    <AnnouncementContext.Provider
      value={{
        announcement,
        loading,
        error,
        updateAnnouncement,
        fetchAnnouncements
      }}
    >
      {children}
    </AnnouncementContext.Provider>
  );
};

export const useAnnouncement = () => {
  const context = useContext(AnnouncementContext);
  if (context === undefined) {
    throw new Error('useAnnouncement must be used within an AnnouncementProvider');
  }
  return context;
};
