
import React from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { ImageIcon, Loader2 } from 'lucide-react';
import { GalleryAlbum } from '@/types/gallery';
import { getImageUrl } from '@/services/galleryService';

type AlbumGridProps = {
  albums: GalleryAlbum[];
  onAlbumClick: (albumId: string) => void;
  isLoading: boolean;
};

export function AlbumGrid({ albums, onAlbumClick, isLoading }: AlbumGridProps) {
  if (isLoading) {
    return (
      <div className="flex justify-center items-center py-16">
        <Loader2 className="w-8 h-8 animate-spin text-primary" />
      </div>
    );
  }

  if (albums.length === 0) {
    return (
      <Card className="bg-muted/50">
        <CardContent className="p-8">
          <div className="text-center py-12">
            <ImageIcon className="mx-auto h-12 w-12 text-muted-foreground mb-4" />
            <h3 className="text-lg font-medium mb-2">No Albums Available</h3>
            <p className="text-muted-foreground">There are currently no photo albums to display.</p>
          </div>
        </CardContent>
      </Card>
    );
  }
  
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {albums.map((album) => (
        <Card key={album.id} className="overflow-hidden hover:shadow-lg transition-shadow">
          <div 
            className="h-52 cursor-pointer relative" 
            onClick={() => onAlbumClick(album.id)}
          >
            {album.coverImageUrl ? (
              <img 
                src={getImageUrl(album.coverImageUrl)} 
                alt={album.title} 
                className="w-full h-full object-cover"
                onError={(e) => {
                  const target = e.target as HTMLImageElement;
                  target.onerror = null;
                  target.style.display = 'none';
                  const parent = target.parentElement;
                  if (parent) {
                    const fallback = document.createElement('div');
                    fallback.className = 'w-full h-full flex items-center justify-center bg-muted';
                    const icon = document.createElement('div');
                    icon.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-12 h-12 text-muted-foreground"><rect width="18" height="18" x="3" y="3" rx="2" ry="2"></rect><circle cx="9" cy="9" r="2"></circle><path d="m21 15-3.086-3.086a2 2 0 0 0-2.828 0L6 21"></path></svg>';
                    fallback.appendChild(icon);
                    parent.appendChild(fallback);
                  }
                }}
              />
            ) : (
              <div className="w-full h-full flex items-center justify-center bg-muted">
                <ImageIcon className="w-12 h-12 text-muted-foreground" />
              </div>
            )}
          </div>
          <CardContent className="p-4">
            <h3 className="text-lg font-medium mb-1">{album.title}</h3>
            {album.description && (
              <p className="text-sm text-muted-foreground mb-2">{album.description}</p>
            )}
            <div className="flex justify-between items-center mt-2">
              <span className="text-xs text-muted-foreground">
                {new Date(album.createdAt).toLocaleDateString()}
              </span>
              <span className="text-xs font-medium">{album.itemCount} photos</span>
            </div>
          </CardContent>
        </Card>
      ))}
    </div>
  );
}
